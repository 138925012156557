<template>
  <BaseLayout>
    <template #drawer-left>
      <Sidebar>
        <v-list>
          <BackListItem
            v-if="meeting"
            :title="$t('Back_to_meeting')"
            :parent="meeting"
            :subtitle="toLocaleDateTimeString(meeting.start)"
          />
        </v-list>
      </Sidebar>
    </template>
    <v-card>
      <v-card-title>
        <h1 class="headline">{{ $t('vorstossCard') }}</h1>
      </v-card-title>
      <v-card-text>
        <div class="d-flex align-center mb-6">
          <v-autocomplete
            v-model="businessTypeChoiceFilter"
            :items="businessTypeChoices"
            :label="$t('Business_type')"
            clearable
            outlined
            dense
            class="mr-4"
            hide-details
            style="max-width: 300px"
          />
          <v-checkbox v-model="canSignFilter" class="ma-0" hide-details :label="$t('vorstossCanBeSigned')" />
        </div>
        <v-divider />
        <v-simple-table v-if="hasVorstoesse" data-testid="vorstoesse-table">
          <thead>
            <tr>
              <th class="text-left text-no-wrap">
                {{ $t('vorstoesseTableTitle') }}
              </th>
              <th class="text-left text-no-wrap">
                {{ $t('vorstoesseTableBusinessType') }}
              </th>
              <th class="text-left text-no-wrap">
                {{ $t('vorstoesseTableFirstSignatory') }}
              </th>
              <th class="text-left text-no-wrap">
                {{ $t('vorstoesseTableOtherSignatoriesCount') }}
              </th>
              <th class="text-left text-no-wrap">
                {{ $t('vorstoesseTableStauts') }}
              </th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="vorstoss in vorstoesse" :key="vorstoss.id" :data-permissions="permissions(vorstoss)">
              <td
                class="text-underline blue--text cursor-pointer"
                data-testid="vorstoss-title"
                @click="openVorstossDialog(vorstoss)"
              >
                {{ vorstoss.title }}
              </td>
              <td class="text-no-wrap" data-testid="vorstoss-business-type">{{ vorstoss.business_type.text }}</td>
              <td class="text-no-wrap" data-testid="vorstoss-first-signatory">{{ firstSignatoryLabel(vorstoss) }}</td>
              <td data-testid="vorstoss-signed-other-signatories-count">
                {{ vorstoss.signed_other_signatories_count }}
              </td>
              <td>
                <v-chip :color="statusChipColor(vorstoss)" data-testid="vorstoss-status">{{
                  vorstoss.status_display
                }}</v-chip>
                <template v-if="!meeting.is_running_meeting && !isSubmitted(vorstoss)">
                  <v-tooltip top>
                    <template #activator="{ on: on, attrs: attrs }">
                      <v-icon class="ml-3" color="yellow" v-bind="attrs" v-on="on">mdi-alert</v-icon>
                    </template>
                    <span>{{ $t('meetingIsNotRunning') }}</span>
                  </v-tooltip>
                </template>
              </td>
              <td class="text-right text-no-wrap">
                <v-menu v-if="canPerformAnyAction(vorstoss)" bottom offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn data-testid="vorstoss-menu" icon v-bind="attrs" v-on="on"
                      ><v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                  </template>
                  <v-list dense class="py-0">
                    <v-list-item v-if="canSign(vorstoss)" data-testid="vorstoss-sign" @click="openSignDialog(vorstoss)">
                      <v-list-item-icon>
                        <v-icon>mdi-file-sign</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('vorstossActionSign') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="canSubmit(vorstoss)"
                      data-testid="vorstoss-submit"
                      @click="openSubmitDialog(vorstoss)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-email-fast-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('vorstossActionSubmit') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="canPublish(vorstoss)" data-testid="vorstoss-publish" @click="publish(vorstoss)">
                      <v-list-item-icon>
                        <v-icon>mdi-hand-extended</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('vorstossActionPublish') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="canEdit(vorstoss)" data-testid="vorstoss-edit" @click="openEditDialog(vorstoss)">
                      <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('vorstossActionEdit') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="canDestroy(vorstoss)" data-testid="vorstoss-destroy" @click="destroy(vorstoss)">
                      <v-list-item-icon>
                        <v-icon color="error">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('vorstossActionDelete') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <p v-else class="mt-4 text-center">{{ $t('emptyVorstoesseTable') }}</p>
      </v-card-text>
    </v-card>
    <VorstossDialog ref="vorstossDialog" @update="fetchVorstoesse" />
    <VorstossSignDialog ref="vorstossSignDialog" @signed="fetchVorstoesse" />
    <VorstossDetailDialog ref="vorstossDetailDialog" />
    <VorstossSubmitDialog ref="vorstossSubmitDialog" @submitted="fetchVorstoesse" />
  </BaseLayout>
</template>

<script>
import { createLink } from '@/api/helpers'
import { api } from '@/api'
import find from 'lodash/find'
import Profile from '@/store/models/profile'
import User from '@/store/models/user'
import get from 'lodash/get'
import Meeting from '@/store/models/meeting'
import isEmpty from 'lodash/isEmpty'
import Committee from '@/store/models/committee'
import { toLocaleDateTimeString } from '@/filters'

const statusChipColors = {
  DRAFT: 'error',
  OPEN: 'warning',
  SUBMITTED: 'success',
}

export default {
  name: 'Vorstoesse',
  data() {
    return {
      vorstoesse: [],
      businessTypeChoices: [],
      currentMeeting: {},
    }
  },
  computed: {
    meetingId() {
      return this.$route.params.id
    },
    meeting() {
      return Meeting.query().find(this.meetingId)
    },
    user() {
      return Profile.query().first()
    },
    isResponsible() {
      return this.user.hasRole('responsible')
    },
    hasVorstoesse() {
      return !isEmpty(this.vorstoesse)
    },
    businessTypeChoiceFilter: {
      get() {
        const businessType = this.$route.query.businessType
        return businessType ? Number.parseInt(businessType) : null
      },
      set(businessType) {
        this.$router.push({ query: { ...this.$route.query, businessType } })
        this.fetchVorstoesse()
      },
    },
    canSignFilter: {
      get() {
        return this.$route.query.canSign === 'true'
      },
      set(canSign) {
        this.$router.push({ query: { ...this.$route.query, canSign } })
        this.fetchVorstoesse()
      },
    },
    canPerformAnyAction() {
      return (vorstoss) =>
        this.canSign(vorstoss) ||
        this.canSubmit(vorstoss) ||
        this.canPublish(vorstoss) ||
        this.canEdit(vorstoss) ||
        this.canDestroy(vorstoss)
    },
  },
  async mounted() {
    const meeting = await Meeting.findOrFetch(this.meetingId)
    const committee = await Committee.findOrFetch(meeting.committee_id)
    this.currentMeeting = meeting
    this.businessTypeChoices = get(committee, 'ris_proposal_business_types', [])
    User.fetchAllByModel(committee)
    this.fetchVorstoesse()
  },
  methods: {
    toLocaleDateTimeString,
    async fetchVorstoesse() {
      const { data } = await api.get(
        createLink('meetings/{id}/vorstoesse', {
          id: this.meetingId,
        }),
        { params: { can_sign: this.canSignFilter, business_type: this.businessTypeChoiceFilter } },
      )
      this.vorstoesse = data
    },
    firstSignatoryLabel(vorstoss) {
      const firstSignatory = this.firstSignatory(vorstoss)
      return (
        [firstSignatory.first_name, firstSignatory.last_name].filter(Boolean).join(' ') +
        ` (${firstSignatory.username})`
      )
    },
    firstSignatory(vorstoss) {
      return find(vorstoss.signatories, { role: 'FIRST_SIGNATORY' })
    },
    isFirstSignatory(vorstoss) {
      return this.user.id === this.firstSignatory(vorstoss).id
    },
    isInDraft(vorstoss) {
      return vorstoss.status === 'DRAFT'
    },
    isOpen(vorstoss) {
      return vorstoss.status === 'OPEN'
    },
    isSubmitted(vorstoss) {
      return vorstoss.status === 'SUBMITTED'
    },
    canEdit(vorstoss) {
      if (this.isSubmitted(vorstoss)) {
        return false
      }
      if (this.isResponsible) {
        return true
      }
      if (this.isOpen(vorstoss)) {
        return false
      }
      return this.isFirstSignatory(vorstoss)
    },
    canSubmit(vorstoss) {
      return this.isOpen(vorstoss) && this.isFirstSignatory(vorstoss) && this.currentMeeting.is_running_meeting
    },
    canPublish(vorstoss) {
      return this.isInDraft(vorstoss) && this.isFirstSignatory(vorstoss)
    },
    canDestroy(vorstoss) {
      if (this.isResponsible) {
        return true
      }
      if (this.isSubmitted(vorstoss)) {
        return false
      }
      return this.isFirstSignatory(vorstoss)
    },
    canSign(vorstoss) {
      return vorstoss.can_sign && this.currentMeeting.is_running_meeting
    },
    permissions(vorstoss) {
      // This is for testing purposes only
      return JSON.stringify({
        canEdit: this.canEdit(vorstoss),
        canSubmit: this.canSubmit(vorstoss),
        canPublish: this.canPublish(vorstoss),
        canDestroy: this.canDestroy(vorstoss),
        canSign: this.canSign(vorstoss),
      })
    },
    openEditDialog(vorstoss) {
      this.$refs.vorstossDialog.openWithVorstoss(vorstoss)
    },
    openSignDialog(vorstoss) {
      this.$refs.vorstossSignDialog.open(vorstoss)
    },
    openDetailDialog(vorstoss) {
      this.$refs.vorstossDetailDialog.open(vorstoss)
    },
    openSubmitDialog(vorstoss) {
      this.$refs.vorstossSubmitDialog.open(vorstoss)
    },
    openVorstossDialog(vorstoss) {
      this.canEdit(vorstoss) ? this.openEditDialog(vorstoss) : this.openDetailDialog(vorstoss)
    },
    statusChipColor(vorstoss) {
      return get(statusChipColors, vorstoss.status, 'grey')
    },
    async publish(vorstoss) {
      try {
        await api.post(createLink('vorstoss/{id}/open', { id: vorstoss.id }))
        this.fetchVorstoesse()
        this.notifySuccess(this.$t('vorstossCardPublishSuccess'))
      } catch (error) {
        this.notifyError(this.$t('vorstossCardPublishError'))
        throw error
      }
    },
    async destroy(vorstoss) {
      if (!confirm(this.$t('vorstoesseDestroyConfirmation'))) {
        return
      }
      try {
        await api.delete(createLink('vorstoss/{id}/', { id: vorstoss.id }))
        this.fetchVorstoesse()
        this.notifySuccess(this.$t('vorstossCardDestroySuccess'))
      } catch (error) {
        this.notifyError(this.$t('vorstossCardDestroyError'))
        throw error
      }
    },
  },
}
</script>
