<template>
  <v-dialog v-model="isOpen" width="800">
    <v-card>
      <v-card-title>
        <h2 class="heading">{{ $t('signVorstossDialogTitle') }}</h2>
      </v-card-title>
      <v-card-text>
        <i18n path="signVorstossDialogText" tag="p">
          <strong>{{ vorstoss.title }}</strong>
        </i18n>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text color="error" @click="cancel">{{ $t('Cancel') }}</v-btn>
        <v-btn data-testid="confirm-sign-button" text color="primary" @click="sign">{{ $t('signVorstossSign') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { createLink } from '@/api/helpers'
import { api } from '@/api'
import get from 'lodash/get'

export default {
  name: 'VorstossSignDialog',
  data() {
    return {
      isOpen: false,
      vorstoss: {},
    }
  },
  methods: {
    async sign() {
      try {
        await api.post(createLink('/vorstoss/{id}/sign', { id: this.vorstoss.id }))
        this.cancel()
        this.$emit('signed')
        this.notifySuccess(this.$t('signVorstossDialogSignSuccess'))
      } catch (error) {
        const message = get(error, 'response.data.non_field_errors', []).join(' ')
        this.notifyError(this.$t('signVorstossDialogSignError', { message }))
      }
    },
    cancel() {
      this.vorstoss = {}
      this.isOpen = false
    },
    open(vorstoss) {
      this.vorstoss = vorstoss
      this.isOpen = true
    },
  },
}
</script>
