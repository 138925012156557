<template>
  <p>redirecting&#8230;</p>
</template>

<script>
export default {
  name: 'Root',
  head: {
    script() {
      const defaultCommitteeId = this.$config.default_committee_id
      if (defaultCommitteeId) {
        this.$router.push({ name: 'committee', params: { id: defaultCommitteeId } })
      } else {
        this.$router.push({ name: 'no-committee' })
      }
    },
  },
}
</script>
